import request from '@/utils/request'

const productApi = {
    update: '/api/product',
    list: '/api/products',
    get: '/api/product/',
    search: '/api/productsearch',
    deleteproduct: '/api/product/',
    addurl: '/api/addurlforproduct',
    aisuggestion: '/api/googleproductsuggestion',
    multiurl: '/api/multiurl',
    pricedata: '/api/productpricedata/',
    corrdata: '/api/productcorrdata/',
    salesdata: '/api/getsalesforproduct/',
    simulation: '/api/changeprice/',
    deleteurl: '/api/deleteurlforproduct',
    deleteproductgoal: '/api/deleteproductgoal',
    geturlprice: 'https://yu29sq2qpa.execute-api.eu-north-1.amazonaws.com/api/test/getpricedata',
    getvalidationid: 'https://yu29sq2qpa.execute-api.eu-north-1.amazonaws.com/api/test/validateurl',
    getvalidationstatus: 'https://yu29sq2qpa.execute-api.eu-north-1.amazonaws.com/api/test/getstatus'  
}

export function deleteproductgoal(parameter){
    return request({
        url: productApi.deleteproductgoal,
        method: 'post',
        data: parameter
    })
}

export function getsalesforproduct(id){
    return request({
        url: productApi.salesdata +id,
        method: 'get'
    });
}

export function getcorrdata(id){
    return request({
        url: productApi.corrdata +id,
        method: 'get'
    });
}

export function getpricedata(id){
    return request({
        url: productApi.pricedata + id,
        method: 'get'
    });
}

export function multiurl(parameter) {
    return request({
        url: productApi.multiurl,
        method: 'post',
        data: parameter
    })
}

export function aisuggestion(parameter) {
    return request({
        url: productApi.aisuggestion,
        method: 'post',
        data: parameter
    })
}

export function addurl(parameter){
    return request({
        url: productApi.addurl,
        method:'post',
        data:parameter,
    })
}
export function deleteurl(parameter) {
    return request({
        url: productApi.deleteurl,
        method: 'post',
        data:parameter
    });
}

export function deleteproduct(id) {
    return request({
        url: productApi.deleteproduct + id,
        method: 'delete'
    });
}
export function addorupdate (parameter) {
    return request({
        url: productApi.update,
        method: 'post',
        data: parameter
    })
}

export function search (parameter) {
    return request({
        url: productApi.search,
        method: 'post',
        data: parameter
    })
}

//list all products when open the page, number: product-01
export function list() {
    return request({
        url: productApi.list,
        method: 'get'
    });
}

export function getitem(id) {
    return request({
        url: productApi.get + id,
        method: 'get'
    });
}

export function simulate(price,id,name) {
    return request({
        url: productApi.simulation + price+'/' +id+'/' +name,
        method: 'post'
    });
}

export function getprice(url){
    return request({
        url: productApi.geturlprice,
        method: 'post',
        data: url,
    });
}

export function getvalidationid(url){
    return request({
        url: productApi.getvalidationid,
        method: 'post',
        data: url,
    });
}

export function getvalidationstatus(testid){
    return request({
        url: productApi.getvalidationstatus,
        method: 'post',
        data: testid,
    });
}